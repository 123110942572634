<template>
  <messages-page
          title="Сообщения касающиеся паев и фондов"
          :messages-list="MESSAGES_PIF"
          :is-nesting="true"
          @read="read"
          @download="download"
  />
</template>

<script>
    import MessagesPage from "@/components/pages/cabinet/messages/MessagesPage";
    import {mapActions, mapGetters} from "vuex";
    export default {
        name: "MessagesPif",
      components: {MessagesPage},
      computed: {
        ...mapGetters("Messages", ["MESSAGES_PIF"])
      },
      methods: {
        ...mapActions("Sockets", ["SOCKET_READ"]),
        ...mapActions("regModule", ["DOWNLOAD_FILE"]),
        read(id) {
          this.SOCKET_READ(id)
        },
        download(file) {
          this.DOWNLOAD_FILE(file)
        }
      }
    }
</script>
